import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  
import styles from '../css/login.module.css';
import Button from '../components/Button';
import { login } from '../services/authService';
import { useAuth } from '../context/AuthContext'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();  
  const { login: loginUser } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token'); // Reemplaza 'token' con el nombre que uses para almacenar el token
    if (token) {
      // Si el token existe, redirige al dashboard
      navigate('/comanda');  
    }
  }, [navigate]); // Cambia history por navigate

  const handleLogin = async (event) => {
    event.preventDefault(); // Evita el comportamiento por defecto del formulario
    setLoading(true); 
    try {
      const response = await login(email, password);
      //console.log(response.restaurante)
      if (response.email && response.token) {
        loginUser(response.token, response.email, response.userid, response.nombre); 
        localStorage.setItem('restaurantName', response.restaurante); 
        navigate('/comanda');  
      } else {
        setError('Error en la respuesta del servidor.');
      }
    } catch (error) {
      setError('Error al iniciar sesión. Verifica tus credenciales.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.divBody}>
        <div className={styles.loginContainer}>
        <h2>Inicio de Sesión</h2>
        
        <form onSubmit={handleLogin}>  
            <div className={styles.formGroup}>
            <input 
              type="text" 
              id="email" 
              placeholder="Usuario" 
              autoComplete='username'
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
            </div>
            <div className={styles.formGroup}>
            <input 
              type="password" 
              placeholder="Contraseña" 
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              required 
            />
            </div>
            <Button label={loading ? 'Cargando...' : 'Ingresar'} variant="default" size="large"/> 
        </form>
        {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    </div>
  );
};

export default Login;
