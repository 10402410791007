import Login from './pages/Login';
import Comanda from './pages/Comanda';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';  
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider> 
      <Router>
        <div className="App">
          {/* Definición de las rutas */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/comanda" element={<ProtectedRoute><Comanda /></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
