import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/header.module.css';
import Button from '../components/Button';
import { useAuth } from '../context/AuthContext';

const Header = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [restaurantName, setRestaurantName] = useState('');
    //const restaurantName = useState(localStorage.getItem('restaurantName') || '');
    useEffect(() => {
        if (!user) {
            navigate('/'); // Redirige a la página de login
        }
    }, [user, navigate]);
    useEffect(() => {
        // Obtén el nombre del restaurante desde localStorage al cargar el componente
        const storedRestaurantName = localStorage.getItem('restaurantName');
        if (storedRestaurantName) {
            setRestaurantName(storedRestaurantName);
        }
    }, []);
    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            
            // Formato de fecha
            const optionsDate = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
            const formattedDate = now.toLocaleDateString('es-ES', optionsDate);

            // Formato de hora (incluyendo segundos)
            const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
            const formattedTime = now.toLocaleTimeString('es-ES', optionsTime);

            setDate(formattedDate);
            setTime(formattedTime);
        };

        updateDateTime(); // Llamada inicial para mostrar la hora al cargar el componente
        const intervalId = setInterval(updateDateTime, 1000); // Actualiza cada segundo

        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
    }, []);
    const handleLogout = () => {
        logout();
        window.location.href = '/';
      };
    return (
        <div className={styles.headerBar}>
            <div className={styles.headerContent}>
                <div className={styles.saasName}>Pallevar</div>
                <div className={styles.restaurantName}>Restaurante "{restaurantName}"</div>
            </div>
            <div className={styles.rightSection}>
                <div className={styles.dateTime}>
                    <span className={styles.date}>{date}</span>
                    <span className={styles.time}>{time}</span>
                </div>
                <Button label='Cerrar Sesion' variant="canceled" size="small" onClick={handleLogout}/>
            </div>
        </div>
    );
};

export default Header;
